<template>
	<div class="pl-store__acticle-card-box" @click="emitClick(listData)">

		<div class="pl-store__acticle-logo">
			<img :src="listData.pic" alt="">
		</div>

		<div class="pl-store__acticle-content-box">
			<div class="pl-store__acticle-title-box">
				<h2 class="pl-store__acticle-title">

					{{ listData.name }}

				</h2>
				<div class="pl-store__acticle-subtitle" v-html="listData.sketch">
				</div>
			</div>
			<div class="pl-store__acticle-info">
				<span>{{ listData.updated_at }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'acticleList',
	props: {
		listData: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	methods: {
		emitClick(data) {
			this.$emit('click', data)
		}

	},

}
</script>
<style lang="scss" scoped>
.pl-store__acticle-card-box {
	//width: 50%;
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	//padding: 15px 0;
	box-sizing: border-box;
	border-bottom: 1px solid #e7e7e7;
	margin: auto;
	background-color: #fff;
	padding: 20px;

	.pl-store__acticle-logo {
		width: 210px;
		height: 140px;


		// border-radius: 15px;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.pl-store__acticle-content-box {
		margin-left: 20px;
		display: flex;
		width: 74%;
		flex-wrap: wrap;


		.pl-store__acticle-title {
			margin-bottom: 10px;
			font-size: 14px;

			h2 {
				font-size: 18px;
			}
		}

		.pl-store__acticle-info {
			width: 100%;
			align-self: flex-end;
			font-size: 12px;
			color: #9b9b9b;
		}

		.pl-store__acticle-subtitle {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			//width: 50%;
      line-height: 1.5;
      font-size: 14px;
		}
	}

}
</style>
