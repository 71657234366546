<template>
	<div class="pl-store__acticle-list-wraper">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>{{ modules[$route.params.active] }}</el-breadcrumb-item>
			<el-breadcrumb-item v-if="currentName">{{ currentName }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="pl-store__acticle-module-title"></div>
		<div class="el-row--flex is-justify-space-between">
			<div class="navLeft">
				<div style="font-size: 16px">{{ modules[$route.params.active] }}</div>
				<div
						style="font-size: 14px" v-for="item in tagList" :key="item.id"
						:class="{ 'active-tag': active_classifyId === item.id }">
					<div @click="handl(item)">{{ item.name }}</div>
				</div>
			</div>
			<div style="width: calc(100% - 260px)">
				<div
            v-if="currentName"
						style="background-color: #fff; padding: 10px 20px;overflow: hidden;">
					<span style="border-left: 3px solid #d9262c; margin-right: 5px"></span> {{ currentName }}
				</div>
				<list v-for="item in list" :listData="item" :key="item.id" @click="goPage"></list>
				<div class="pl-store__pagination" v-if="list.length">
					<el-pagination
							background layout="prev, pager, next" :page-size="param.per_page" @current-change="change"
							:total="total">
					</el-pagination>
				</div>
				<el-empty :description="`暂无内容`" v-if="list.length === 0"></el-empty>
			</div>
		</div>
	</div>
</template>
<script>
import list from '@/components/acticle/list.vue'
import {acticleList, acticleClassifyList} from '@/api/acticle.js'

export default {
	components: {
		list
	},
	data() {
		return {
			list: [],
			tagList: [],
			param: {
				page: 1,
				per_page: 15,
				type: ''
			},
			total: 0,
			navList: [],
			currentActive: 0,
			currentName: '',
			active_classifyId: '',
			modules: {
				0: '资讯中心', 1: '帮助中心', 3: '设计师赋能', 4: '平台公告'
			}
		}
	},
	created() {
		// this.currentName = this.tagTitleList[this.$route.params.active]
		this.active_classifyId = this.$route.params.classifyId ? parseInt(this.$route.params.classifyId) : ''
		acticleClassifyList({...this.param, type: this.$route.params.active}).then(({data}) => {
			this.tagList = data || []
			this.currentName = this.tagList.filter(item => {
				return item.id === this.active_classifyId
			})[0]?.name
			this.getArticleList()
		})
	},
	methods: {
		handl(item) {
			this.active_classifyId = item.id
			// this.list = item.acticle
			this.currentName = item.name
			this.getArticleList()
		},
		goPage(data) {
			this.$router.push(`/news/acticle/${data.id}`)
		},
		change(val) {
			this.param.page = val
			this.getArticleList()
		},
		getArticleList() {
			acticleList({...this.param, type: this.$route.params.active, classifyId: this.active_classifyId})
					.then(({data, total}) => {
						this.list = data || []
						this.total = total || 0
					})
		},
		handleClick(id) {
			this.currentActive = id
			this.getCurrentName()
			this.$router.push(`/news/${id}`)
		},
		tagFun(id, index, isT) {
			console.log(id)
			if (this.active == index && !isT) return false
			this.active = index
			this.param.type = id
			this.param.page = 1
			console.log('------param', this.param)
			acticleList(this.param).then(({data, total}) => {
				this.list = data || []
				this.total = total || 0
			})
		},
		getCurrentName() {
			this.navList.forEach(item => {
				if (item.id === this.currentActive) {
					this.currentName = item.name
				}
			})
		}
	},
	mounted() {
		if (!this.$route.params.active) {
			this.currentActive = 0
		} else {
			this.currentActive = this.$route.params.active
		}
		this.getCurrentName()
		this.tagFun(this.currentActive, this.currentActive, true)
	},
	watch: {
		$route(to, from) {
			this.currentActive = parseInt(to.params.active)
			this.tagFun(this.currentActive, this.currentActive, true)
		}
	},
}
</script>
<style lang="scss" scoped>
.navLeft {
	width: 240px;
	height: 300px;
	background-color: #fff;
	//position: absolute;
}

.navLeft div {
	width: 100%;
	height: 50px;
	line-height: 50px;
	text-align: center;
	//font-weight: bold;
}

.navLeft div:hover {
	//background-color: #FDF4F4;
	cursor: pointer;
}

.active-tag {
	background-color: #fdf4f4 !important;
	color: #333333;
}

.pl-store__acticle-module-title {
	padding: 20px 0 12px 0;
	margin-bottom: 30px;
	cursor: pointer;

	> span {
		padding-right: 15px;
	}

	.active {
		color: #3385FF;
	}
}

.pl-store__pagination {
	text-align: center;
	margin-top: 20px;
}

::v-deep {
	.pl-store__acticle-card-box .pl-store__acticle-logo img {
		width: 186px;

	}

	.pl-store__acticle-card-box .pl-store__acticle-logo {
		overflow: none;
	}

	.pl-store__acticle-logo {
		overflow: none;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #d9262c;
	}

	.pl-store__acticle-card-box .pl-store__acticle-content-box .pl-store__acticle-subtitle p,
	.pl-store__acticle-card-box .pl-store__acticle-content-box .pl-store__acticle-subtitle span {
		font-size: 14px !important;
	}

	// .pl-store__acticle-card-box .pl-store__acticle-content-box .pl-store__acticle-subtitle[data-v-bb95de50] {
	//     height: 95px;
	// }
}
</style>
